.Form-Login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Form-Login div {
    margin: 1rem 0;


}

#empty-space {
    background-color: rgb(91,164,233);
}

@media (max-width: 991.98px) {
    
}