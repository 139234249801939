/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2022 */



@font-face {
    font-family: 'pipebold';
    src: url('./Fonts/Pipe-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'pipeheavy';
    src: url('./Fonts/Pipe-Heavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'pipelight';
    src: url('./Fonts/Pipe-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'piperegular';
    src: url('./Fonts/Pipe-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pipexbold';
    src: url('./Fonts/Pipe-XBold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;

}