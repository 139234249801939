body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  font-family: sans-serif;
  text-align: center;
  overflow: hidden;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
html, body, #app, #app>div {
  height: 100%;
}
#root {
  height: 100%;
}