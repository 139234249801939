#loader-container {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#loader path {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}

#loader path:nth-child(2) {
    animation-delay: 0.1s;
}

#loader path:nth-child(3) {
    animation-delay: 0.2s;
}

#loader path:nth-child(4) {
    animation-delay: 0.3s;
}

#loader path:nth-child(5) {
    animation-delay: 0.4s;
}

#loader path:nth-child(6) {
    animation-delay: 0.6s;
}

#loader path:nth-child(7) {
    animation-delay: 0.8s;
}

#loader path:nth-child(8) {
    animation-delay: 1s;
}

#loader path:nth-child(9) {
    animation-delay: 1.1s;
}

#loader path:nth-child(10) {
    animation-delay: 1.35s;
}

#loader path:nth-child(11) {
    animation-delay: 1.75s;
}

#loader path:nth-child(12) {
    animation-delay: 2s;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    #loader-container {
        max-width: 150px;
    }
    #loader {
        width: 150px;
    }
}

@media screen and (max-width: 650px) {
    #loader-container {
        max-width: 100px;
    }
    #loader {
        width: 100px;
    }
}
